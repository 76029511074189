<template>
    <div class="main">
        <div class="main_box">
            <el-tabs type="border-card" stretch>
                <el-tab-pane label="造价趋势图">
                    <div class="flex_column main_chart" style="padding-top: 40px;">
                        <h2 class="main_chart_title">各地住宅建安造价指标对比图（元/㎡）</h2>
                        <div class="flex_row_center main_chart_choose">
                            <div class="main_chart_choose_select">
                                <el-select v-model="hierarchyItem" placeholder="请选择" @change="getCecnNewsGetReportAreaData">
                                    <el-option
                                        v-for="item in hierarchyList"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="main_chart_choose_select">
                                <el-select v-model="trendTimeItem" placeholder="请选择" @change="getCecnNewsGetReportAreaData">
                                    <el-option
                                        v-for="item in trendTimeList"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div ref="chart" class="main_chart_box"></div>
                    </div>
                    <div class="flex_column main_chart">
                        <h2 class="main_chart_title">各地住宅价格走势图（元/㎡）</h2>
                        <div class="flex_row_center main_chart_choose">
                            <div class="main_chart_choose_select">
                                <el-select v-model="addressItem" placeholder="请选择" @change="getCecnNewsGetReportCityData">
                                    <el-option
                                        v-for="item in addressList"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div ref="chart1" class="main_chart_box"></div>
                    </div>
                    <div class="flex_column main_chart">
                        <h2 class="main_chart_title">全国住宅成本走势图（元/㎡）</h2>
                        <div ref="chart2" class="main_chart_box"></div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="城市住宅造价信息">
                    <div class="vice">
                        <div class="vice_box">
                            <h2 class="vice_title">城市住宅造价信息</h2>
                            <!--<div class="vice_select">
                              <el-select v-model="value1" placeholder="请选择" style="width: 100%;">
                                <el-option
                                  v-for="item in options"
                                  :key="item.value"
                                  :label="item.label"
                                  :value="item.value">
                                </el-option>
                              </el-select>
                            </div>-->
                            <div class="vice_table">
                                <div class="flex_center vice_table_item" v-for="(item, index) in housingData.records" :key="index">
                                    <p>{{item.title}}</p>
                                    <span @click="onLink(item, 'housingData')">立即查看</span>
                                </div>
                            </div>
                            <div class="vice_pagination">
                                <el-pagination
                                    @current-change="(e) => handleCurrentChange(e, 'housingData')"
                                    :current-page.sync="currentPage"
                                    :page-size="housingData.size"
                                    layout="total, prev, pager, next, jumper"
                                    :total="housingData.total">
                                </el-pagination>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="人工成本信息">
                    <div class="vice">
                        <div class="vice_box">
                            <h2 class="vice_title">人工成本信息</h2>
                            <div class="vice_table">
                                <div class="flex_center vice_table_item" v-for="(item, index) in artificialData.records" :key="index">
                                    <p>{{item.title}}</p>
                                    <span @click="onLink(item, 'artificialData')">立即查看</span>
                                </div>
                            </div>
                            <div class="vice_pagination">
                                <el-pagination
                                    @current-change="(e) => handleCurrentChange(e, 'artificialData')"
                                    :current-page.sync="currentPage1"
                                    :page-size="artificialData.size"
                                    layout="total, prev, pager, next, jumper"
                                    :total="artificialData.total">
                                </el-pagination>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
    import * as echarts from 'echarts';
    import cache from "@/libs/util.cache.js";
    import { urlCecnNewsGetSubsTime, urlCecnNewsGetSubsType, urlCecnNewsGetReportAreaData, urlCecnNewsGetReportCityData, urlCecnNewsGetReportData, urlCecnNewsGetReportCityList, urlCecnNewsGetReportDown } from '@/api/engineering'
    export default {
        name: "tendencyChart",
        data() {
            return {
                option: {
                    dataset: [
                        {
                            dimensions: ['name', 'age'],
                            source: []
                        },
                        {
                            transform: {
                                type: 'sort',
                                config: { dimension: 'age', order: 'desc' }
                            }
                        }
                    ],
                    xAxis: {
                        type: 'category',
                        axisLabel: { interval: 0, rotate: 30 }
                    },
                    yAxis: {},
                    series: {
                        type: 'bar',

                        label: {
                            show: true,
                            position: 'top',
                            valueAnimation: true,
                            fontFamily: 'monospace'
                        }
                    }
                },
                option1: {
                    color: ['#007EF2', '#36CFC9', '#FF804D'],
                    tooltip: {
                        trigger: 'none',
                        axisPointer: {
                            type: 'cross'
                        }
                    },
                    legend: {},
                    grid: {
                        top: 70,
                        bottom: 50
                    },
                    xAxis: {
                        type: 'category',
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLine: {
                            onZero: false,
                            lineStyle: {
                                color: '#4E4E4E'
                            }
                        },
                        axisPointer: {
                            label: {
                                formatter: function (params) {
                                    return (
                                        params.value +
                                        (params.seriesData.length ? '：' + params.seriesData[0].data : '')
                                    );
                                }
                            }
                        },
                        data: []
                    },
                    yAxis: {
                        type: 'value',
                    },
                    series: [
                        {
                            name: '高层',
                            type: 'line',
                            smooth: true,
                            emphasis: {
                                focus: 'series'
                            },
                            data: []
                        },
                        {
                            name: '小高层',
                            type: 'line',
                            smooth: true,
                            emphasis: {
                                focus: 'series'
                            },
                            data: []
                        },
                        {
                            name: '多层',
                            type: 'line',
                            smooth: true,
                            emphasis: {
                                focus: 'series'
                            },
                            data: []
                        }
                    ]
                },
                option2: {
                    color: ['#007EF2', '#36CFC9', '#FF804D'],
                    tooltip: {
                        trigger: 'none',
                        axisPointer: {
                            type: 'cross'
                        }
                    },
                    legend: {},
                    grid: {
                        top: 70,
                        bottom: 50
                    },
                    xAxis: {
                        type: 'category',
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLine: {
                            onZero: false,
                            lineStyle: {
                                color: '#4E4E4E'
                            }
                        },
                        axisPointer: {
                            label: {
                                formatter: function (params) {
                                    return (
                                        params.value +
                                        (params.seriesData.length ? '：' + params.seriesData[0].data : '')
                                    );
                                }
                            }
                        },
                        data: []
                    },
                    yAxis: {
                        type: 'value',
                    },
                    series: [
                        {
                            name: '高层',
                            type: 'line',
                            smooth: true,
                            emphasis: {
                                focus: 'series'
                            },
                            data: []
                        },
                        {
                            name: '小高层',
                            type: 'line',
                            smooth: true,
                            emphasis: {
                                focus: 'series'
                            },
                            data: []
                        },
                        {
                            name: '多层',
                            type: 'line',
                            smooth: true,
                            emphasis: {
                                focus: 'series'
                            },
                            data: []
                        }
                    ]
                },
                hierarchyList: [],
                hierarchyItem: '',
                trendTimeList: [],
                trendTimeItem: '',
                addressList: [],
                addressItem: '',
                housingData: {},
                artificialData: {},
                currentPage: 1,
                currentPage1: 1,
                size: 20,
                value1: '',
                options: [{
                    value: '选项1',
                    label: '黄金糕'
                }, {
                    value: '选项2',
                    label: '双皮奶'
                }, {
                    value: '选项3',
                    label: '蚵仔煎'
                }, {
                    value: '选项4',
                    label: '龙须面'
                }, {
                    value: '选项5',
                    label: '北京烤鸭'
                }],
            }
        },
        async mounted() {
            this.getChart = echarts.init(this.$refs.chart)
            this.getChart1 = echarts.init(this.$refs.chart1)
            this.getChart2 = echarts.init(this.$refs.chart2)
            this.getCecnNewsGetReportCityData()
            this.getCecnNewsGetReportAreaData()
            this.getCecnNewsGetReportData()
            await this.getCecnNewsGetSubsTime()
            await this.getCecnNewsGetSubsType()
            await this.getCecnNewsGetReportCityList()
            this.housingData = await this.getCecnNewsGetReportDown(`current=${this.currentPage}&size=${this.size}&type=城市住宅造价信息`)
            this.artificialData = await this.getCecnNewsGetReportDown(`current=${this.currentPage1}&size=${this.size}&type=人工成本信息`)
        },
        methods: {
            onLink(e, type) {
                if (type === 'housingData') {
                    const obj = JSON.parse(e.downJson)
                    for (let key in obj) {
                        window.open(obj[key], "_blank");
                    }
                } else {
                    cache.setSessionStorage("tendencyChart", e);
                    this.$router.push({path: "/engineering/tendency-chart-details", query: { detailId: e.id }});
                }
            },
            async getCecnNewsGetReportDown(param) {
                const res = await urlCecnNewsGetReportDown(param)
                return res.data
            },
            async getCecnNewsGetReportCityList() {
                const res = await urlCecnNewsGetReportCityList()
                this.addressList = res.data
                this.addressItem = res.data[0].id
            },
            async getCecnNewsGetReportData() {
                const res = await urlCecnNewsGetReportData()
                const obj = JSON.parse(res.data.valueJson)
                this.option2.xAxis.data = obj.name
                this.option2.series[0].data = obj.valueGC
                this.option2.series[1].data = obj.valueXGC
                this.option2.series[2].data = obj.valueDC
                this.getChart2.setOption(this.option2)
            },
            async getCecnNewsGetReportCityData() {
                const param = `cityId=${this.addressItem}`
                const res = await urlCecnNewsGetReportCityData(param)
                if (!res.data) return
                const obj = JSON.parse(res.data.valueJson)
                this.option1.xAxis.data = obj.name
                this.option1.series[0].data = obj.valueGC
                this.option1.series[1].data = obj.valueXGC
                this.option1.series[2].data = obj.valueDC
                this.getChart1.setOption(this.option1)
            },
            async getCecnNewsGetSubsType() {
                const param = ``
                const res = await urlCecnNewsGetSubsType(param)
                this.hierarchyList = res.data
                this.hierarchyItem = res.data[0].id
            },
            async getCecnNewsGetSubsTime() {
                const param = ``
                const res = await urlCecnNewsGetSubsTime(param)
                this.trendTimeList = res.data
                this.trendTimeItem = res.data[0].id
            },
            async getCecnNewsGetReportAreaData() {
                const param = `timeId=${this.trendTimeItem}&typeId=${this.hierarchyItem}`
                const res = await urlCecnNewsGetReportAreaData(param)
                const obj = JSON.parse(res.data.valueJson)
                let arr = []
                obj.name.forEach((item, index) => {
                    arr.push([item, obj.value[index]])
                })
                this.option.dataset[0].source = arr
                this.getChart.setOption(this.option)
            },
            handleClick(tab, event) {
                console.log(tab, event);
            },
            async handleCurrentChange(val, type) {
                if (type === 'housingData') {
                    this.currentPage = val
                    this.housingData = await this.getCecnNewsGetReportDown(`current=${this.currentPage}&size=${this.size}&type=城市住宅造价信息`)
                } else if (type === 'artificialData') {
                    this.currentPage1 = val
                    this.artificialData = await this.getCecnNewsGetReportDown(`current=${this.currentPage1}&size=${this.size}&type=人工成本信息`)
                }
            }
        }
    }
</script>

<style scoped lang="less">
    .main {
        .main_box {
            .vice {
                text-align: left;
                .vice_box {
                    .vice_title {
                        font-size: 24px;
                        font-weight: bold;
                        color: #21262D;
                        padding-bottom: 20px;
                    }
                    .vice_select {
                        width: 340px;
                        padding-bottom: 20px;
                    }
                    .vice_table {
                        border: 1px solid #DCE5F2;
                        border-bottom: none;
                        .vice_table_item {
                            height: 56px;
                            padding: 0 20px;
                            border-bottom: 1px solid #DCE5F2;
                            font-size: 16px;
                            font-weight: 500;
                            color: #4E4E4E;
                            > p {
                                flex: 1;
                                padding-right: 20px;
                            }
                            > span {
                                color: #1B5C98;
                                cursor: pointer;
                            }
                        }
                    }
                    .vice_pagination {
                        padding-top: 20px;
                    }
                }
            }
            .main_chart {
                padding-bottom: 100px;
                .main_chart_title {
                    font-size: 24px;
                    font-weight: bold;
                    color: #21262D;
                    padding-bottom: 20px;
                }
                .main_chart_choose {
                    padding-bottom: 20px;
                    .main_chart_choose_select {
                        padding: 0 20px;
                    }
                }
                .main_chart_box {
                    width: 1170px;
                    height: 500px;
                }
            }
        }
    }
    /deep/ .el-tabs--border-card {
        background: none;
        border: none;
        box-shadow: none;
    }
    /deep/ .el-tabs--border-card>.el-tabs__header {
        background: #F8F8F8;
        padding: 4px;
        border: none;
    }
    /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
        border: 0;
        border-radius: 8px;
        background-color: #fff;
        font-weight: bold;
        color: #007EF2;
    }
    /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item {
        height: 52px;
        border: 0;
        line-height: 52px;
        font-size: 24px;
    }
</style>
